import React from 'react';
import OneSlider from './OneSlider';
import Brand from './Brand';
import GamesArea from './GamesArea';
import GamesManage from './GamesManage';
import FeaturesArea from './FeaturesArea';
import ShopArea from './ShopArea';
import HeaderOne from '../../header/HeaderOne';
import Footer from '../../footer/Footer';
import AboutUsArea from './AboutUsArea';
import Blogarea from '../../../pages/blogs/Blogarea';
import TeamMem from '../../../pages/aboutpage/aboutuspage/TeamMem';
import ContactArea from '../../../pages/contact/ContactArea';
import CareersArea from '../../../pages/contact/CareersArea';
import FooterThree from '../../footer/FooterThree';
import FooterFive from '../../footer/FooterFive';
import SlideDown from 'react-slidedown';
import $ from 'jquery';


export default class IndexOne extends React.Component {
  constructor(props) {
    super(props);
    this.contactareaRef = React.createRef();
    this.state = {
      contactareaClosed: true,
    }
  }

  render() {
    const handleHeaderAction = (component) => {
      const ref = this.contactareaRef;
      const statearea = 'contactareaClosed';

      this.setState({[statearea]: false});

      $('body').removeClass('mobile-menu-visible');
      setTimeout(() => {
        window.scrollTo({
          top: ref.current.offsetTop - 100,
          left: 0,
          behavior: "smooth",
        });
      }, this.state[statearea] ? 500 : 0);
    };

    return (
      <>
          <HeaderOne handleHeaderAction={handleHeaderAction}/>
          <main>
            <OneSlider/>
            <div className="brand-area brand-bg"></div> {/* Separator */}
            <Blogarea latestOnly="true"/>
            <GamesArea/>
            <AboutUsArea/>
            <TeamMem/>
            <SlideDown ref={this.contactareaRef} closed={this.state.contactareaClosed}>
              <ContactArea/>
            </SlideDown>
          </main>
          <Footer/>
      </>
    )
  }
}
