import React from "react";
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import ReactPlayer from 'react-player'
import { FaChevronUp, FaChevronDown, FaChevronLeft, FaChevronRight } from 'react-icons/fa';

export default class GamesAreatwo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      arrival: props.arrival,
      abilitySelected: null,
      abilitySelectedIndex: 0,
      smallScreenShowAbilities: false,
      windowWidth: window.innerWidth,
      currentAbility: null
    };
    this.abilityselect = (ref) => {
      if (ref === this.state.abilitySelected) { // Allow you to close the selected ability
        ref = null;
      }
      this.setState(state => ({ abilitySelected: ref, abilitySelectedIndex: 0 }));
    }
    this.handleLeftClick = (abilityType, ability) => {
      this.setState(state => ({abilitySelected: abilityType, abilitySelectedIndex: state.abilitySelectedIndex === 0 ? ability.augments.length : state.abilitySelectedIndex - 1}));
    };
    this.handleRightClick = (abilityType, ability) => {
      if (abilityType !== this.state.abilitySelected) {
        this.setState(state => ({abilitySelectedIndex: 0})); // Reset index if switching abilities
      }
      this.setState(state => ({abilitySelected: abilityType, abilitySelectedIndex: state.abilitySelectedIndex === ability.augments.length ? 0 : state.abilitySelectedIndex + 1}));
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.arrival !== prevProps.arrival) { // New arrival selected, reset ability selection
      this.setState({arrival: this.props.arrival, abilitySelected: null, abilitySelectedIndex: 0});
    }
  }

  componentDidMount() {
    const { height, updateItem, id } = this.props;

    window.addEventListener('resize', this.handleWindowResize);

    // When item is mounted, if it's height is 0
    // we want to expand it
    if (height === 0) {
      requestAnimationFrame(() => {
        updateItem(id, {
          height: "auto"
        });
      });
    }

  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }
  
  handleWindowResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  render() {
    const { children, height } = this.props;
    const isSmallScreen = this.state.windowWidth <= 768;
    const abilityChevronStyle = {
      cursor: 'pointer',
      position: 'absolute',
      marginBottom: '5px',
    };
    const abilityChevronStyleLeft = {
      left: '0'
    };
    const abilityChevronStyleRight = {
      right: '20px'
    };
    const arrival = this.state.arrival;

    return (
      <SlideDown className={'my-dropdown-slidedown'} closed={this.props.closed}>
        <section className="just-gamers-bg pt-115 pb-120">
          <div className="just-gamers-area">
            <div className="container">
              {/* TODO TC - Remove when showing abilities (justify-content-center) */}
              <div className="row justify-content-center">
                <div className="col-8 order-sm-1 order-md-1 order-lg-1 order-xl-1">
                  <div className="section-title title-style-three white-title mb-20">
                    <h2><span>{this.props.arrival.name}</span></h2>
                    <p>{arrival?.description}</p>
                    {/* TODO TC - Uncomment when showing abilities */}
                    {/* {isSmallScreen ? (
                      <div className="just-gamers-show-abilities" onClick={() => this.setState({smallScreenShowAbilities: !this.state.smallScreenShowAbilities})}>
                        <div className={`icon-slide ${this.state.smallScreenShowAbilities ? 'down' : 'up'}`}>
                          <FaChevronUp />
                        </div>
                        <p>&nbsp;Show Abilities</p>
                      </div>
                    ) : null} */}
                  </div>
                </div>
                <div className="col-12 order-sm-2 order-md-3 order-lg-3 order-xl-3">
                  <div className="just-gamers-img">
                    <img src={"assets/img/arrivals/" + arrival.render} alt="" className="just-gamers-character" />
                  </div>
                  {/* TODO TC - Uncomment when showing abilities */}
                  {/* <div className="just-gamers-img" style={this.state.smallScreenShowAbilities == false && isSmallScreen ? {visibility: 'hidden', position: 'relative'} : null}>
                    <img src="assets/img/images/just_gamers_img.png" alt="" className="just-gamers-character" />
                  </div> */}
                </div>
                {/* TODO TC - Uncomment when showing abilities */}
                {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 order-sm-3 order-md-2 order-lg-2 order-xl-2">
                  <SlideDown closed={this.state.smallScreenShowAbilities == false && isSmallScreen}>
                    <div className="just-gamers-list">
                      <ul>
                        {arrival && arrival.abilities && Object.keys(arrival.abilities).map(abilityType => {
                          const baseAbility = arrival.abilities[abilityType];
                          // Get selected augment, if any, otherwise get the base ability
                          const ability = this.state.abilitySelectedIndex === 0 || this.state.abilitySelected != abilityType || !baseAbility.augments ? baseAbility : baseAbility.augments[this.state.abilitySelectedIndex - 1];
                          const abilitySelected = this.state.abilitySelected === abilityType;

                          return (
                            <><li className="d-flex align-items-center align-items-start" onClick={() => this.abilityselect(abilityType)}>
                              <div className={`just-gamers-list-icon ${abilitySelected ? 'selected' : ''}`}>
                                <img src={"assets/img/icon/abilities/" + ability.icon} alt="" />
                              </div>
                              <div className="just-gamers-list-content fix">
                                {abilitySelected && baseAbility.augments && this.state.abilitySelectedIndex > 0 && (
                                  <FaChevronLeft style={{...abilityChevronStyle, ...abilityChevronStyleLeft}} onClick={(e) => {this.handleLeftClick(abilityType, baseAbility); e.stopPropagation()}}></FaChevronLeft>
                                )}
                                <h5>{ability.name}</h5>
                                {baseAbility.augments && (!abilitySelected || this.state.abilitySelectedIndex < baseAbility.augments.length) && (
                                  <FaChevronRight style={{...abilityChevronStyle, ...abilityChevronStyleRight}} onClick={(e) => {this.handleRightClick(abilityType, baseAbility); e.stopPropagation()}}></FaChevronRight>
                                )}
                              </div>
                            </li><div className="just-gamers-video">
                                <SlideDown closed={this.state.abilitySelected !== abilityType}>
                                  <p>{baseAbility.description}</p>
                                  <p style={{color: "#36C6C6"}}>{ability !== baseAbility ? ability.description : "" }</p>
                                  <ReactPlayer
                                    url={ability.video}
                                    width={'100%'}
                                    height={'100%'}
                                    controls={true} />
                                </SlideDown>
                              </div></>
                          )
                        })}
                      </ul>
                    </div>
                  </SlideDown>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </SlideDown>
    )
  }
}