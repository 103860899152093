import React from 'react'
import Footer from '../../components/footer/Footer';
import HeaderOne from '../../components/header/HeaderOne';
import CareersArea from './CareersArea';


function Contact() {
  return (
	<>
	<HeaderOne/>
	<main>
		<CareersArea/>
	</main>
	<Footer/>
	</>
  )
}

export default Contact