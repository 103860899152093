import React from "react";
import {Link} from 'react-router-dom';

export default class Blogarea extends React.Component {
  render() {
    const latestOnly = this.props.latestOnly;

    return (
	   <section id="NewsComponent" className={`blog-area primary-bg ${!latestOnly && 'pt-120 pb-175' || 'pt-50'}`}>
        <div className="container">
          {latestOnly &&
          <div className="col-12 section-title title-style-three text-center mb-60">
            <h2><span>News and Updates</span></h2>
          </div>
          }
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="blog-list-post">
                <div className="blog-list-post-thumb">
                  <Link to="/"><img src="assets/img/blog/t_blog_thumb01.jpg" alt="" /></Link>
                </div>
                <div className="blog-list-post-content">
                  <div className="blog-list-post-tag mb-25">
                    <a href="/#">Introduction</a>
                  </div>
                  <h2><a href="/#">Introducing Conflux: A New Era of MOBA Gaming</a></h2>
                  <div className="blog-meta">
                    <ul>
                      <li>MARCH 5, 2024</li>
                    </ul>
                  </div>
                  <p>Conflux is currently in development, but we're working tirelessly to create an immersive, competitive, and balanced gaming experience
                    that will captivate both new and veteran MOBA players. Our game features unique heroes, innovative gameplay mechanics, and stunning visuals
                    that we can't wait for you to experience.</p>
                </div>
              </div>
              {!latestOnly && <>
              <div className="blog-list-post">
                <div className="blog-list-post-thumb">
                  <Link to="/blog-details"><img src="assets/img/blog/t_blog_thumb02.jpg" alt="" /></Link>
                </div>
                <div className="blog-list-post-content">
                  <div className="blog-list-post-tag mb-25">
                    <a href="/#">Introduction</a>
                  </div>
                  <h2><a href="/#">Introducing Conflux: A New Era of MOBA Gaming</a></h2>
                  <div className="blog-meta">
                    <ul>
                      <li>MARCH 5, 2024</li>
                    </ul>
                  </div>
                  <p>Conflux is currently in development, but we're working tirelessly to create an immersive, competitive, and balanced gaming experience
                    that will captivate both new and veteran MOBA players. Our game features unique heroes, innovative gameplay mechanics, and stunning visuals
                    that we can't wait for you to experience.</p>
                </div>
              </div><div className="blog-list-post">
                  <div className="blog-list-post-thumb">
                    <Link to="/blog-details"><img src="assets/img/blog/t_blog_thumb03.jpg" alt="" /></Link>
                  </div>
                  <div className="blog-list-post-content">
                    <div className="blog-list-post-tag mb-25">
                      <a href="/#">Introduction</a>
                    </div>
                    <h2><a href="/#">Introducing Conflux: A New Era of MOBA Gaming</a></h2>
                    <div className="blog-meta">
                      <ul>
                        <li>MARCH 5, 2024</li>
                      </ul>
                    </div>
                    <p>Conflux is currently in development, but we're working tirelessly to create an immersive, competitive, and balanced gaming experience
                      that will captivate both new and veteran MOBA players. Our game features unique heroes, innovative gameplay mechanics, and stunning visuals
                      that we can't wait for you to experience.</p>
                  </div>
                </div><div className="pagination-wrap mt-60">
                  <ul>
                    <li><a href="/#">first</a></li>
                    <li><a href="/#">1</a></li>
                    <li><a href="/#">2</a></li>
                    <li><a href="/#">next</a></li>
                  </ul>
                </div></>
              }
            </div>
            {!latestOnly && <>
            <div className="col-lg-4">
              <aside className="blog-sidebar">
                <div className="widget mb-45">
                  <div className="sidebar-about">
                    <div className="sidebar-about-thumb">
                      <img src="assets/img/blog/sidebar_about_thumb.jpg" alt="" />
                    </div>
                    <div className="sidebar-widget-title mb-15">
                      <h4>ABOUT THE <span>AUTHOR</span></h4>
                    </div>
                    <div className="sidebar-about-content">
                      <p>Donec orci enim, bibendum a augue quis, aliquet cursus quamPellentesq.</p>
                    </div>
                  </div>
                </div>
                <div className="widget mb-45">
                  <div className="sidebar-search">
                    <form action="/#">
                      <input type="text" placeholder="Type and hit enter..." />
                      <button><i className="fas fa-search" /></button>
                    </form>
                  </div>
                </div>
                <div className="widget mb-45">
                  <div className="sidebar-widget-title mb-25">
                    <h4>category <span>post</span></h4>
                  </div>
                  <div className="sidebar-cat">
                    <ul>
                      <li><a href="/#">ESPORTS ( 12 )</a></li>
                      <li><a href="/#">play store ( 03 )</a></li>
                      <li><a href="/#">pubg ( 09 )</a></li>
                      <li><a href="/#">zombieland ( 07 )</a></li>
                      <li><a href="/#">shooting ( 02 )</a></li>
                    </ul>
                  </div>
                </div>
                <div className="widget mb-45">
                  <div className="sidebar-widget-title mb-25">
                    <h4>Recent <span>post</span></h4>
                  </div>
                  <div className="rc-post-list">
                    <ul>
                      <li>
                        <div className="rc-post-thumb">
                          <a href="/#"><img src="assets/img/blog/rc_post_thumb01.jpg" alt="" /></a>
                        </div>
                        <div className="rc-post-content">
                          <h5><a href="/#">EVERYONE zombie</a></h5>
                          <span>OCTOBER 19, 2022</span>
                        </div>
                      </li>
                      <li>
                        <div className="rc-post-thumb">
                          <a href="/#"><img src="assets/img/blog/rc_post_thumb02.jpg" alt="" /></a>
                        </div>
                        <div className="rc-post-content">
                          <h5><a href="/#">Archery World Tour</a></h5>
                          <span>OCTOBER 19, 2022</span>
                        </div>
                      </li>
                      <li>
                        <div className="rc-post-thumb">
                          <a href="/#"><img src="assets/img/blog/rc_post_thumb03.jpg" alt="" /></a>
                        </div>
                        <div className="rc-post-content">
                          <h5><a href="/#">The Ranger Magic</a></h5>
                          <span>OCTOBER 19, 2022</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="widget mb-45">
                  <div className="sidebar-widget-title mb-25">
                    <h4>TRENDING <span>MATCHES</span></h4>
                  </div>
                  <div className="sidebar-trending-match">
                    <ul>
                      <li>
                        <div className="sidebar-trend-match-thumb">
                          <a href="/#"><img src="assets/img/blog/trend_match_thumb01.png" alt="" /></a>
                        </div>
                        <div className="sidebar-trend-match-action">
                          <span>0</span> <span>-</span> <span>3</span>
                        </div>
                        <div className="sidebar-trend-match-thumb">
                          <a href="/#"><img src="assets/img/blog/trend_match_thumb02.png" alt="" /></a>
                        </div>
                        <div className="sidebar-trend-match-date">
                          <span>OCTOBER 19, 2022</span>
                        </div>
                      </li>
                      <li>
                        <div className="sidebar-trend-match-thumb">
                          <a href="/#"><img src="assets/img/blog/trend_match_thumb03.png" alt="" /></a>
                        </div>
                        <div className="sidebar-trend-match-action">
                          <span>0</span> <span>-</span> <span>7</span>
                        </div>
                        <div className="sidebar-trend-match-thumb">
                          <a href="/#"><img src="assets/img/blog/trend_match_thumb04.png" alt="" /></a>
                        </div>
                        <div className="sidebar-trend-match-date">
                          <span>OCTOBER 19, 2022</span>
                        </div>
                      </li>
                      <li>
                        <div className="sidebar-trend-match-thumb">
                          <a href="/#"><img src="assets/img/blog/trend_match_thumb05.png" alt="" /></a>
                        </div>
                        <div className="sidebar-trend-match-action">
                          <span>0</span> <span>-</span> <span>3</span>
                        </div>
                        <div className="sidebar-trend-match-thumb">
                          <a href="/#"><img src="assets/img/blog/trend_match_thumb06.png" alt="" /></a>
                        </div>
                        <div className="sidebar-trend-match-date">
                          <span>OCTOBER 19, 2022</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="widget">
                  <div className="sidebar-widget-title mb-25">
                    <h4>TAGS <span>post</span></h4>
                  </div>
                  <div className="sidebar-tag-list">
                    <ul>
                      <li><a href="/#">pubg</a></li>
                      <li><a href="/#">dota 2</a></li>
                      <li><a href="/#">zombie</a></li>
                      <li><a href="/#">call of duty</a></li>
                      <li><a href="/#">development</a></li>
                      <li><a href="/#">zombieland 2</a></li>
                    </ul>
                  </div>
                </div>
              </aside>
            </div></>
            }
          </div>
          {/* TODO: Uncomment when there are multiple news posts */}
          {/* <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-8">
              <div className="section-title text-center mb-60">
                <a href="/blogs" className="btn btn-style-two">Read More</a> 
              </div>
            </div>
          </div> */}
        </div>
      </section>
    )
  }
}