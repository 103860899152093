import React from 'react';

function Footer() {
  return (
	   <footer>
        <div className="footer-top footer-bg">
          {/* newsletter-area */}
          <div className="newsletter-area">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="newsletter-wrap">
                    <div className="about-btn">
                      <a href="/#" className="btn btn-style-two">Coming Soon</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* newsletter-area-end */}
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="footer-widget mb-50">
                  <div className="footer-logo mb-35">
                    <a href="/"><img src="assets/img/logo/logo-colored.png" alt="" /></a>
                  </div>
                  <div className="footer-text">
                    <div className="footer-contact">
                      <ul>
                        <li><i className="fas fa-envelope-open" /><span>Email : </span>info@confluxthehereafter.com</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              { /* TODO TC - Uncomment when we have more to link to */ }
              { /*
              <div className="col-xl-2 col-lg-3 col-sm-6">
                <div className="footer-widget mb-50">
                  <div className="fw-title mb-35">
                    <h5>Need Help?</h5>
                  </div>
                  <div className="fw-link">
                    <ul>
                      <li><a  href="/#">Terms &amp; Conditions</a></li>
                      <li><a  href="/#">Privacy Policy</a></li>
                      <li><a  href="/#">Refund Policy</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              */ }
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="footer-widget mb-50">
                  <div className="fw-title mb-35">
                    <h5>Follow us</h5>
                  </div>
                  <div className="footer-social">
                    <ul>
                      <li><a  href="https://www.linkedin.com/company/convergence-studios"><i className="fab fa-linkedin-in" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-wrap">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6">
                <div className="copyright-text">
                  <p>Copyright © 2024 Convergence Studios, <a  href="/#">Conflux</a> All Rights Reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
  )
}

export default Footer;
