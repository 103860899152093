import React from 'react';



function OneSlider() {

  return (
	  <section id="HomeComponent" className="slider-area slider-bg" style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6"></div> {/* This empty div will take up the left half of the screen */}
            <div className="col-lg-6"> {/* This div will take up the right half of the screen */}
              <div className="slider-content text-center">
                <img src="assets/img/logo/conflux-logo.png" width="100%"/>
                <p className="wow fadeInUp" data-wow-delay="2s">MOBA-Style Third Person Shooter</p>
                {/* <a href="/#" className="btn wow fadeInUp" data-wow-delay="2.2s">Coming Soon</a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default OneSlider;
