import React from 'react'

function TeamMem() {
  const members = [
    {
      name: "Jaxyn De Lozier",
      title: "CEO; Narrative Lead",
      image: "Jaxyn_De_Lozier.png"
    },
    {
      name: "Treyten Carey",
      title: "Programming Lead",
      image: "Treyten_Carey.jpg",
      externals: [
        {
          className: "fab fa-linkedin",
          url: "https://www.linkedin.com/in/treyten-carey-617362151"
        },
        {
          className: "fab fa-github",
          url: "https://github.com/treytencarey/"
        },
        {
          className: "fas fa-globe",
          url: "https://treytencarey.com/"
        }
      ]
    },
    {
      name: "Adam Momsen",
      title: "3D Animator; Rigger",
      image: "Adam_Momsen.png",
      externals: [
        {
          className: "fab fa-linkedin",
          url: "https://www.linkedin.com/in/adammomsen/"
        },
        {
          className: "fas fa-globe",
          url: "https://www.adammomsen.com/"
        }
      ]
    },
    {
      name: "Fane Guinoiseau",
      title: "3D Department Lead",
      image: "Fane_Guinoiseau.png"
    },
    {
      name: "Kat Craig",
      title: "UI/UX Lead",
      image: "Kat_Craig.png",
      externals: [
        {
          className: "fab fa-linkedin",
          url: "https://www.linkedin.com/in/katacraig/?trk=opento_sprofile_topcard"
        },
        {
          className: "fas fa-globe",
          url: "https://www.katcraigdesign.com/"
        }
      ]
    },
    {
      name: "Lauren Caraballo",
      title: "3D Character Artist",
      image: "Lauren_Caraballo.png",
      externals: [
        {
          className: "fab fa-linkedin",
          url: "https://www.linkedin.com/in/lcaraballo2022"
        }
      ]
    },
    {
      name: "Matthias-Daniel Zerez",
      title: "3D Character Artist",
      image: "Matthias-Daniel_Zerez.png"
    },
    {
      name: "Sofia Alejandrino",
      title: "2D Concept Artist",
      image: "Sofia_Alejandrino.JPG"
    },
    {
      name: "Owen Stickley",
      title: "3D Environment Artist",
      image: "Owen_Stickley.jpg",
      externals: [
        {
          className: "fab fa-artstation",
          url: "https://www.artstation.com/lostepoch"
        }
      ]
    },
    {
      name: "Philip Khan",
      title: "2D Concept Artist",
      image: "Philip_Khan.jpg",
      externals: [
        {
          className: "fab fa-linkedin",
          url: "https://www.linkedin.com/in/philip-khan-0949a5283/"
        },
        {
          className: "fas fa-globe",
          url: "https://philipkhan.myportfolio.com/"
        }
      ]
    },
    {
      name: "Ingrid Mondolis",
      title: "2D Concept Artist",
      image: "Ingrid_Mondolis.png",
      externals: [
        {
          className: "fas fa-globe",
          url: "https://minrii.carrd.co/"
        },
        {
          className: "fas fa-globe",
          url: "https://ingridmondolis.wixsite.com/website/"
        }
      ]
    },
    {
      name: "Daneal Jackson",
      title: "Concept Artist",
      image: "Daneal_Jackson.jpg"
    },
    {
      name: "Dominik Lieseberg",
      title: "3D Environment/Prop Artist",
      image: "Dominik_Lieseberg.jpg",
      externals: [
        {
          className: "fab fa-linkedin",
          url: "https://www.linkedin.com/in/dominik-lieseberg-b58546225/"
        },
        {
          className: "fab fa-artstation",
          url: "https://www.artstation.com/dominik_lbg"
        }
      ]
    },
    {
      name: "Destiny Cooper",
      title: "2D Character Artist",
      image: "Destiny_Cooper.png"
    },
    {
      name: "Adam Doolittle",
      title: "Game Designer",
      image: "Adam_Doolittle.jpg",
      externals: [
        {
          className: "fas fa-globe",
          url: "https://adamisaiahdoolittle.dev/"
        },
        {
          className: "fab fa-linkedin",
          url: "https://www.linkedin.com/in/adamisaiahdoolittle/"
        }
      ]
    },
    {
      name: "Yongjoon Kim",
      title: "Composer",
      image: "Yongjoon_Kim.jpeg",
      externals: [
        {
          className: "fab fa-youtube",
          url: "https://www.youtube.com/@kimyongjnmusic"
        },
        {
          className: "fab fa-imdb",
          url: "https://www.imdb.com/name/nm15810506/"
        }
      ]
    }
  ];
  return (
	 <section id="UsComponent" className="area-bg-one team-member-area pt-115 pb-125">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
              <div className="section-title title-style-three text-center mb-70">
                <h2><span>Our Team</span></h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            {members.sort(() => Math.random() - 0.5).map((member, index) => (
              <div key={index} className={`col-lg-3 col-sm-6 ${index % 5 === 4 ? 'w-100' : ''}`}>
                <div className="team-member-box text-center mb-50">
                  <div className="team-member-thumb">
                    <div className="team-member-image-wrapper">
                      <img src={"assets/img/team/" + member.image} alt="" className="team-member-image" />
                    </div>
                  </div>
                  <div className="team-member-social">
                    <ul>
                      {member.externals?.map((external, index) => (
                          <li key={index}>
                            <a href={external.url} target="_blank" rel="noopener noreferrer">
                              <i className={external.className} />
                            </a>
                          </li>
                      ))}
                    </ul>
                  </div>
                  <div className="team-member-content">
                    <h4><a href="/#">{member.name}</a></h4>
                    <span>{member.title}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
  )
}

export default TeamMem