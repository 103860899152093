import React,{useEffect} from 'react';
import $ from 'jquery';
import ScrollToTop from "react-scroll-to-top";
import ScrollTo from '../scrolltotop/ScrollTo';

export default class IndexOne extends React.Component {

  constructor(props) {
    super(props);
  }
  
  componentDidMount(){
    //SubMenu Dropdown Toggle
      if ($('.menu-area li.menu-item-has-children ul').length) {
        $('.menu-area .navigation li.menu-item-has-children').append('<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>');

      }

      //Mobile Nav Hide Show
      if ($('.mobile-menu').length) {

        var mobileMenuContent = $('.menu-area .push-menu').html();
        $('.mobile-menu .menu-box .menu-outer').append(mobileMenuContent);

        //Dropdown Button
        $('.mobile-menu li.menu-item-has-children .dropdown-btn').on('click', function () {
          $(this).toggleClass('open');
          $(this).prev('ul').slideToggle(500);
        });


        $('.menu-backdrop, .mobile-menu .close-btn').click (()=>{
          $('body').removeClass('mobile-menu-visible');
        })


        //Menu Toggle Btn
        $('.mobile-nav-toggler').on('click', function () {
          $('body').addClass('mobile-menu-visible');
        });
      }
      
    $(window).on('scroll', function () {
      var scroll = $(window).scrollTop();
      if (scroll < 245) {
        $("#sticky-header").removeClass("sticky-menu");
        $('.scroll-to-target').removeClass('open');
    
      } else {
        $("#sticky-header").addClass("sticky-menu");
        $('.scroll-to-target').addClass('open');
      }
    });
  }
  
  render() {
    return (
      <header>
        <ScrollToTop smooth top="500" color="#000" />
          <div id="sticky-header" className="main-header menu-area">
            <div className="container-fluid container-full-padding">
              <div className="row">
                <div className="col-12">
                <div className="mobile-nav-toggler"><i className="fas fa-bars" /></div>
                  <div className="main-menu">
                    <nav>
                      <div className="logo">
                        <ScrollTo offset={-200} componentId="Home">
                          <img src="assets/img/logo/logo-colored.png" alt="Logo" />
                        </ScrollTo>
                      </div>
                    
                      <div className="navbar-wrap push-menu d-none d-lg-flex">
                        <ul className="navigation">
                          <ScrollTo offset={-170}>News</ScrollTo>
                          <ScrollTo offset={-110}>Arrivals</ScrollTo>
                          <ScrollTo offset={-130}>Tales</ScrollTo>
                          <li className="menu-item-has-children"><ScrollTo offset={-110} componentId="Us">About Us</ScrollTo>
                            <ul className="submenu">
                              <ScrollTo onClick={() => this.props.handleHeaderAction && this.props.handleHeaderAction("Contact")}>Contact</ScrollTo>
                              <ScrollTo link={"/careers"} offset={-200} onClick={() => this.props.handleHeaderAction && this.props.handleHeaderAction("Careers")}>Careers</ScrollTo>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                <div className="mobile-menu">
                    <nav className="menu-box">
                      <div className="close-btn"><i className="fas fa-times" /></div>
                      <ScrollTo offset={-200} componentId="Home">
                        <div className="nav-logo"><img src="assets/img/logo/logo.png" alt="" title='true' /></div>
                      </ScrollTo>
                      <ul className="navigation">
                        <ScrollTo offset={-170}>News</ScrollTo>
                        <ScrollTo offset={-110}>Arrivals</ScrollTo>
                        <ScrollTo offset={-130}>Tales</ScrollTo>
                        <li className="menu-item-has-children"><ScrollTo offset={-110} componentId="Us">About Us</ScrollTo>
                          <ul className="submenu">
                            <ScrollTo onClick={() => this.props.handleHeaderAction && this.props.handleHeaderAction("Contact")}>Contact</ScrollTo>
                            <ScrollTo link={"/careers"} offset={-200} onClick={() => this.props.handleHeaderAction && this.props.handleHeaderAction("Careers")}>Careers</ScrollTo>
                          </ul>
                        </li>
                      </ul>
                      {/* <div className="menu-outer">
                    
                      </div> */}
                      <div className="social-links">
                        <ul className="clearfix">
                          <li><a href="/#"><span className="fab fa-twitter" /></a></li>
                          <li><a href="/#"><span className="fab fa-facebook-square" /></a></li>
                          <li><a href="/#"><span className="fab fa-pinterest-p" /></a></li>
                          <li><a href="/#"><span className="fab fa-instagram" /></a></li>
                          <li><a href="/#"><span className="fab fa-youtube" /></a></li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                  <div className="menu-backdrop" />
                </div>
                {/* Modal Search */}
                <div className="modal fade" id="search-modal" tabIndex={-1} role="dialog" aria-hidden="true">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <form>
                        <input type="text" placeholder="Search here..." />
                        <button><i className="fa fa-search" /></button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
    )
  }
}
