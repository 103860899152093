import React from 'react';
import GamesAreatwo from '../indextwo/GamesAreatwo';

export default class GamesArea extends React.Component {
  constructor(props) {
    super(props);
    this.areatwoRef = React.createRef();
    this.handleToggle = (el, name, arrival) => {
      // Toggle if no previous arrival selected or if the same arrival was selected to view again
      if (this.state.closed || !this.state.arrivalEl || el.target == this.state.arrivalEl) {
        this.setState(state => ({ closed: !state.closed }));
      }
      this.setState({arrivalEl: el.target, arrival: {name: name, ...arrival}});
      // Scroll to section if opening or if a new arrival was selected to view
      if (this.state.closed || el.target != this.state.arrivalEl) {
        setTimeout(() => {
          window.scrollTo({
            top: this.areatwoRef.current.offsetTop - 100,
            left: 0,
            behavior: "smooth",
          });
        }, 200);
      }
    };
    this.state = {
      closed: true,
      arrival: {
        name: undefined
      },
      arrivals: {}
    };
  }

  componentDidMount() {
    fetch('assets/data/Arrivals.json')
      .then(response => response.json())
      .then(arrivals => {
        this.setState({arrivals: arrivals});
      });
  }
  
  render() {
  return (
	  <div>
      <div className="area-bg-one">
        {/* latest-games-area */}
        <section id="ArrivalsComponent" className="latest-games-area pt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-8">
                <div className="section-title mb-50">
                  <h2><span>Arrivals</span></h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              { Object.entries(this.state.arrivals).map(([name, arrival]) => (
                <div key={name} className="col-lg-4 col-md-6">
                  <div className="latest-games-thumb">
                    <img onClick={e => this.handleToggle(e, name, arrival)} src={"assets/img/arrivals/" + arrival.portrait} alt="" />
                  </div>
                  <div className="latest-games-content">
                    <div className="lg-tag">
                      <a href="/#">{arrival.reality}</a>
                    </div>
                    <h4 onClick={e => this.handleToggle(e, name, arrival)}><span>{name}</span></h4>
                    <p>{arrival.title}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        </div>
        <div ref={this.areatwoRef}>
          <GamesAreatwo closed={this.state.closed} arrival={this.state.arrival}/>
        </div>
      {/* latest-games-area-end */}
      {/* about-us-area */}
      
      {/* about-us-area-end */}
    </div>
  )
  }
}
