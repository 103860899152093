import React,{ useEffect } from 'react';
import 'magnific-popup';
import $ from 'jquery';
import {Link} from 'react-router-dom';

function AboutUsArea() {
  return (
	  <section id="TalesComponent" className="about-us-area pt-90 pb-120">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4">
                <div className="section-title title-style-three mb-45">
                  <h2><span>Tales from the Spindown</span></h2>
                </div>
                <div className="about-content">
                  <p>The Spin-Down multiverse is a vast nebula of differing worlds and stories where our Arrivals originally come from. Star Girls to Manifest West, Empire of Glass to Gilded Path each world is packed with massive amounts of lore and stories for you to read.</p>
                  { /* TODO TC - Uncomment when there is more to link to */ }
                  { /*
                  <div className="about-btn">
                    <a href="/#" className="btn btn-style-two">READ MORE</a>
                  </div>
                  */ }
                </div>
              </div>
              <div className="col-lg-8">
                <img src="assets/img/images/GroupPhoto.png" alt="" className="img-fluid" />
              </div>
            </div>
            <div className="row justify-content-center">
              <br/>
              <p>Learn more about your favorite character, or what lead up to them showing up into Tel'Kah, and immerse yourself into the vast worlds of the Spin-Down. There are two types of stories you will find, Short Stories and Light Novels; Short Stories are broken up into 4
                  smaller chunks that are updated every week until their conclusion and offer looks into side stories from the world. The Light Novel is a much longer anc definitive story of that reality, offering a much longer read and will be updated across a much longer time preriod
                  until the stories conclusion.</p>
            </div>
          </div>
        </section>
  )
}

export default AboutUsArea;
