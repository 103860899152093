import React, { useState } from 'react';

function ContactArea(props) {
  // props = {
  //   title: 'Contact Us',
  //   button: 'Submit Message',
  //   contactType: 'Open Application',
  //   attachment: 'Resume or CV',
  //   template_id: 'template_1',
  // }
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [contactType, setContactType] = useState('');

  const sendEmail = async (e) => {
    e.preventDefault();

    const formData = {
      email: email,
      name: name,
      message: message,
      contactType: props.contactType || '',
    }

    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }
    data.append("attachment", document.querySelector('#resume')?.files[0]);
    
    const response = await fetch('/api/contact', {
      method: 'POST',
      body: data,
    });
    if (!response.ok) {
      alert('There was a problem sending the message, please try again.');
      throw new Error('Network response was not ok');
    } else {
      alert('Message sent successfully.');
    }

  };

  return (
    <section id="ContactComponent" className="contact-area pt-40 pb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-title title-style-three mb-45">
              <h2><span>{props.title ? props.title : 'Contact Us'}</span></h2>
            </div>
            <div className="contact-form">
              <form onSubmit={sendEmail} enctype="multipart/form-data">
                <textarea name="message" id="message" placeholder="Write Message..." value={message} onChange={(e) => setMessage(e.target.value)} />
                <div className="row">
                  <div className="col-md-6">
                    <input type="text" placeholder="Your Name" value={name} onChange={(e) => setName(e.target.value)} />
                  </div>
                  <div className="col-md-6">
                    <input type="email" placeholder="Your Mail" value={email} onChange={(e) => setEmail(e.target.value)} />
                  </div>
                </div>
                {props.contactType && (
                  <input style={{display: 'none'}} value={contactType} type="text" />
                )}
                {props.attachment && (
                  <div className="col-md-12">
                    <label htmlFor="resume">{props.attachment ? props.attachment : 'File'}</label>
                    <input type="file" id="resume" name="resume" accept=".pdf,.doc,.docx" placeholder="Resume or CV" />
                  </div>
                )}
                <button type="submit" className="btn btn-style-two">{props.button ? props.button : 'Submit Message'}</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactArea;