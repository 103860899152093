import React from 'react'
import {Link} from 'react-router-dom'



function GamesArea() {

  
  return (
	 <section className="featured-game-area position-relative pt-115 pb-90">
            <div className="featured-game-bg" />
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-8">
                  <div className="section-title title-style-three text-center mb-70">
                    <h2>JUST FEATURED <span>GAMES</span></h2>
                    <p>Compete with 100 players on a remote island for winner takes showdown
                      known issue where certain skin strategic</p>
                  </div>
                </div>
              </div>
              <div className="row featured-active">
                <div className="col-lg-4 col-sm-6 grid-item">
                  <div className="featured-game-item mb-30">
                    <div className="featured-game-thumb">
                      <img src="assets/img/images/featured_game_thumb01.jpg" alt="" />
                    </div>
                    <div className="featured-game-content">
                      <h4><Link to="/#">JUST FOR <span>GAMERS</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>Playstation 5 , Xbox</span>
                      </div>
                    </div>
                    <div className="featured-game-content featured-game-overlay-content">
                      <div className="featured-game-icon"><img src="assets/img/icon/featured_game_icon.png" alt="" /></div>
                      <h4><Link to="/#">JUST FOR <span>GAMERS</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>Playstation 5 , Xbox</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 grid-item">
                  <div className="featured-game-item mb-30">
                    <div className="featured-game-thumb">
                      <img src="assets/img/images/featured_game_thumb02.jpg" alt="" />
                    </div>
                    <div className="featured-game-content">
                      <h4><Link to="/#">JUST FOR <span>GAMERS</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>Playstation 5 , Xbox</span>
                      </div>
                    </div>
                    <div className="featured-game-content featured-game-overlay-content">
                      <div className="featured-game-icon"><img src="assets/img/icon/featured_game_icon.png" alt="" /></div>
                      <h4><Link to="/#">JUST FOR <span>GAMERS</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>Playstation 5 , Xbox</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 grid-item">
                  <div className="featured-game-item mb-30">
                    <div className="featured-game-thumb">
                      <img src="assets/img/images/featured_game_thumb03.jpg" alt="" />
                    </div>
                    <div className="featured-game-content">
                      <h4><Link to="/#">JUST FOR <span>GAMERS</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>Playstation 5 , Xbox</span>
                      </div>
                    </div>
                    <div className="featured-game-content featured-game-overlay-content">
                      <div className="featured-game-icon"><img src="assets/img/icon/featured_game_icon.png" alt="" /></div>
                      <h4><Link to="/#">JUST FOR <span>GAMERS</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>Playstation 5 , Xbox</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 grid-item">
                  <div className="featured-game-item mb-30">
                    <div className="featured-game-thumb">
                      <img src="assets/img/images/featured_game_thumb04.jpg" alt="" />
                    </div>
                    <div className="featured-game-content">
                      <h4><Link to="/#">JUST FOR <span>GAMERS</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>Playstation 5 , Xbox</span>
                      </div>
                    </div>
                    <div className="featured-game-content featured-game-overlay-content">
                      <div className="featured-game-icon"><img src="assets/img/icon/featured_game_icon.png" alt="" /></div>
                      <h4><Link to="/#">JUST FOR <span>GAMERS</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>Playstation 5 , Xbox</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 grid-item">
                  <div className="featured-game-item mb-30">
                    <div className="featured-game-thumb">
                      <img src="assets/img/images/featured_game_thumb05.jpg" alt="" />
                    </div>
                    <div className="featured-game-content">
                      <h4><Link to="/#">JUST FOR <span>GAMERS</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>Playstation 5 , Xbox</span>
                      </div>
                    </div>
                    <div className="featured-game-content featured-game-overlay-content">
                      <div className="featured-game-icon"><img src="assets/img/icon/featured_game_icon.png" alt="" /></div>
                      <h4><Link to="/#">JUST FOR <span>GAMERS</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>Playstation 5 , Xbox</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 grid-item">
                  <div className="featured-game-item mb-30">
                    <div className="featured-game-thumb">
                      <img src="assets/img/images/featured_game_thumb06.jpg" alt="" />
                    </div>
                    <div className="featured-game-content">
                      <h4><Link to="/#">JUST FOR <span>GAMERS</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>Playstation 5 , Xbox</span>
                      </div>
                    </div>
                    <div className="featured-game-content featured-game-overlay-content">
                      <div className="featured-game-icon"><img src="assets/img/icon/featured_game_icon.png" alt="" /></div>
                      <h4><Link to="/#">JUST FOR <span>GAMERS</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>Playstation 5 , Xbox</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
  )
}

export default GamesArea