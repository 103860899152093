import React,{useEffect} from 'react';
import { Link, withRouter } from 'react-router-dom'
import $ from 'jquery';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

class ScrollTo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            offset: props.offset || 0,
            link: props.link || '/',
            onClick: props.onClick || null,
        }
        this.onClick = this.onClick.bind(this);
    }

    componentDidMount() {
        this.checkHashAndClick();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.checkHashAndClick();
        }
    }

    checkHashAndClick() {
        const hash = this.props.location.hash;
        const componentId = (this.props.componentId || this.props.children);
        if (hash === '#' + componentId) {
            setTimeout(() => {
                this.onClick();
            }, 500);
        }
    }

    onClick() {
        if (this.props.location.pathname !== this.state.link) {
            window.location.href = this.state.link + "#" + (this.props.componentId || this.props.children);
        } else {
            const newUrl = this.state.link + "#" + (this.props.componentId || this.props.children);
            window.history.pushState({}, '', newUrl);

            this.state.onClick && this.state.onClick();
            $('body').removeClass('mobile-menu-visible');
            window.scrollTo({
                top: document.getElementById((this.props.componentId || this.props.children) + 'Component').offsetTop + this.state.offset,
                behavior: 'smooth'
            });
        }

    }

    render() {
        return (
                <li style={{cursor: "pointer"}} onClick={this.onClick}>
                    <a>
                        {this.props.children}
                    </a>
                </li>
            )
    }
}

export default withRouter(ScrollTo);