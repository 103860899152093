import React from 'react';
import ReactMarkdown from 'react-markdown';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import ContactArea from './ContactArea';
import YAML from 'yaml';

class CareersArea extends React.Component {
  constructor(props) {
    super(props);
    this.careerRef = React.createRef();
    this.handleToggle = (el, title, career) => {
      const queryParams = new URLSearchParams(window.location.search);
      if (this.state.career.title !== title) {
        this.setState({career: {title: title, info: career}});
        queryParams.set('career', title); // Update the career query parameter
        this.props.history.push(`${window.location.pathname}?${queryParams.toString()}`);
      }
      setTimeout(() => {
        window.scrollTo({
          top: this.careerRef.current.offsetTop - 200,
          left: 0,
          behavior: "smooth",
        });
      }, 0);
    };
    this.state = {
      careers: {},
      career: {
        title: undefined,
        info: undefined
      }
    };
  }

  componentDidMount() {
    fetch('assets/data/Careers.yaml')
      .then(response => response.text())
      .then(careers => {
        const parsedCareers = YAML.parse(careers);
        const projectDetails = parsedCareers['Project Details'].description;
        delete parsedCareers['Project Details'];
        Object.values(parsedCareers).forEach(career => {
          career.description = career.description + '\n\n' + projectDetails;
        });
        this.setState({careers: parsedCareers});
        setTimeout(() => {
          this.scrollToCareer();
        }, 500);
      });
  }

  scrollToCareer() {
    const queryParams = new URLSearchParams(this.props.location.search);
    const career = queryParams.get('career');
    if (career && career in this.state.careers) {
      this.handleToggle(null, career, this.state.careers[career]);
    }
  }

  render() {
  return (
    <section id="CareersComponent" className="careers-area pt-90 pb-120">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="section-title title-style-three mb-45">
              <h2><span>Careers</span></h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          { Object.entries(this.state.careers).map(([title, career]) => (
            <div key={title} className="col-lg-4 col-md-6">
              <div className="careers-thumb">
                { /* TODO TC - Uncomment when we have icons */ }
                { /*<img onClick={e => this.handleToggle(e, title, career)} src={"assets/img/portraits/MiMi.png"} alt="" /> */ }
              </div>
              <div className="careers-content">
                <h4 onClick={e => this.handleToggle(e, title, career)}>{title}</h4>
              </div>
            </div>
          ))}
        </div>
        <div className="row justify-content-center" ref={this.careerRef}>
          {this.state.career.title && (
            <div className="col-6">
              <div className="section-title title-style">
                <h2 style={{justifyContent: "center", display: "flex"}}><span>{this.state.career.title}</span></h2>
                <p><ReactMarkdown>{this.state.career.info?.description}</ReactMarkdown></p>
              </div>
            </div>
          )}
        </div>
        {this.state.career.title && (
          <ContactArea template_id="template_ivijkc8" contactType={'Application: ' + this.state.career.title} title="Apply" button="Submit Application" attachment="Resume or CV" />
        )}
      </div>
    </section>
    )
  }
}

export default withRouter(CareersArea);